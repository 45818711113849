import React from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { PrimaryBtn } from "components/library"
import { SecureAnalysisIllustration } from "components/library/illustrations/secure-analysis-illustration";

export const Hero = () => {
	const { t } = useTranslation()

	return (
		<section className="grid grid-cols-1 sm:grid-cols-2 items-center gap-16">
			<div className="flex flex-col gap-6">
				<span className="tracking-wider uppercase">
					{t("security-page.hero.label")}
				</span>
				<span className="text-4xl tracking-tight sm:text-5xl">
					{t("security-page.hero.heading")}
				</span>
				<p className="sm:text-lg md:text-xl">{t("security-page.hero.desc")}</p>

				<Link to="/security-report">
					<PrimaryBtn tabIndex={-1} className="max-w-max sm:text-lg">
						{t("security-report-page.cta")}
					</PrimaryBtn>
				</Link>
			</div>

			<div className="h-full">
				<SecureAnalysisIllustration className="w-full h-full" />
			</div>
		</section>
	)
}
